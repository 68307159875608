<!-- @format -->

<template>
  <section class="payment container">
    <div class="payment-box">
      <h2 class="payment__h2 text-center">
        {{
          $cookie.get("lang") === "Ru"
            ? "Публичная Оферта"
            : "Құпиялылық саясаты"
        }}
      </h2>
      <p>
        {{
          $cookie.get("lang") === "Ru"
            ? `Интернет-магазин Tahit расположенный на доменном имени www.tahit.kz
        пренадлежащий ТОО «ТАИТ» с юридическим адресом Кунаева 43, в лице
        директора Дуйсеновой Гульнар Мухамедияровны, действующего (- ей) Решения
        именуемое в дальнейшем «Продавец», публикует Публичную оферту о продаже
        Товара дистанционным способом.`
            : `www.tahit.kz домендік атауында орналасқан Tahit интернет-дүкені
         заңды мекен-жайы Қонаев 43, өкілі болып табылатын «TAIT» ЖШС-не тиесілі
         директоры Дүйсенова Гүлнар Мұхамедьярқызы, міндетін атқарушы (- оған) Шешім
         бұдан әрі «Сатушы» деп аталатын, сатуға арналған Қоғамдық ұсынысты жариялайды
         Тауарлар қашықтан.`
        }}
      </p>
    </div>
    <div class="payment-box">
      <h2 class="payment__h2 text-start">
        {{
          $cookie.get("lang") === "Ru"
            ? "1.	ОПРЕДЕЛЕНИЕ ТЕРМИНОВ"
            : "1. ТЕРМИНДЕРДІ АНЫҚТАУ"
        }}
      </h2>
      <p>
        {{
          $cookie.get("lang") === "Ru"
            ? `1.1.	Публичная оферта (далее – «Оферта») - публичное предложение Продавца, адресованное неопределенному кругу лиц, заключить с Продавцом договор купли-продажи товара дистанционным способом (далее - «Договор») на условиях, содержащихся в настоящей Оферте, включая все Приложения.
1.2.	Заказ Товара на сайте Интернет-магазина – позиции указанные Покупателем из ассортимента Товара, предложенного к продаже, при оформлении заявки на приобретение Товара на сайте Интернет-магазина или через Оператора.
`
            : `1.1. Ашық оферта (бұдан әрі – Оферта) – Сатушының тауарды қашықтықтан сату шартын жасасуға арналған, тұлғалардың белгісіз шеңберіне бағытталған ашық ұсынысы (бұдан әрі – Шарт) ) барлық Қосымшаларды қоса алғанда, осы Ұсыныста қамтылған шарттар бойынша.
1.2. Интернет-дүкеннің веб-сайтында Тауарға тапсырыс беру - Интернет-дүкеннің веб-сайтында немесе Оператор арқылы Тауарды сатып алуға тапсырыс беру кезінде сатуға ұсынылатын Тауарлар ассортиментінен Сатып алушы көрсеткен заттар.`
        }}
      </p>
    </div>
    <div class="payment-box">
      <h2 class="payment__h2 text-start">
        {{
          $cookie.get("lang") === "Ru"
            ? "2.	ОБЩИЕ ПОЛОЖЕНИЯ"
            : "2. ЖАЛПЫ ЕРЕЖЕЛЕР"
        }}
      </h2>
      <p>
        {{
          $cookie.get("lang") === "Ru"
            ? `2.1. Заказ Покупателем Товара, размещенного на сайте Интернет-магазина означает, что Покупатель согласен со всеми условиями настоящей Оферты.
2.2. Администрация сайта Интернет-магазина имеет право вносить изменения в Оферту без уведомления Покупателя.
2.3. Срок действия Оферты не ограничен, если иное не указано на сайте Интернет-магазина.
2.4. Продавец предоставляет Покупателю полную и достоверную информацию о Товаре, включая информацию об основных потребительских свойствах Товара, месте изготовления, а также информацию о гарантийном сроке и сроке годности Товара на сайте Интернет магазина, в разделе  название раздела.

`
            : `2.1. Сатып алушының интернет-дүкеннің веб-сайтында жарияланған Тауарға тапсырысы Сатып алушының осы Ұсыныстың барлық шарттарымен келісетінін білдіреді.
2.2. Интернет-дүкен сайтының әкімшілігі Сатып алушыны ескертпестен Ұсынысқа өзгерістер енгізуге құқылы.
2.3. Интернет-дүкеннің веб-сайтында басқаша көрсетілмесе, Ұсыныстың әрекет ету мерзімі шектелмейді.
2.4. Сатушы Сатып алушыға Тауар туралы толық және сенімді ақпаратты, оның ішінде Тауардың негізгі тұтынушылық қасиеттері, өндірілген жері туралы ақпаратты, сондай-ақ онлайн веб-сайтта Тауардың кепілдік мерзімі мен жарамдылық мерзімі туралы ақпаратты ұсынады. дүкеннің бөлім тақырыбы бөлімінде.`
        }}
      </p>
    </div>
    <div class="payment-box">
      <h2 class="payment__h2 text-start">
        {{
          $cookie.get("lang") === "Ru" ? "3.	ЦЕНА ТОВАРА " : "3. ТАУАР БАҒАСЫ"
        }}
      </h2>
      <p>
        {{
          $cookie.get("lang") === "Ru"
            ? `3.1. Цена на каждую позицию Товара указана на сайте Интернет-магазина. 
3.2. Продавец имеет право в одностороннем порядке изменить цену на любую позицию Товара. 
3.3. В случае изменения цены на заказанный Товар Продавец обязуется в течение  количество дней проинформировать Покупателя об изменении цены Товара.
3.4. Покупатель вправе подтвердить либо аннулировать Заказ на приобретение Товара, если цена изменена Продавцом после оформления Заказа.
3.5. Изменение Продавцом цены на оплаченный Покупателем Товар не допускается. 
3.6. Продавец указывает стоимость доставки Товара на сайте Интернет-магазина либо сообщает Покупателю при оформлении заказа Оператором.
3.7. Обязательства Покупателя по оплате Товара считаются исполненными с момента поступления Продавцом денежных средств.
3.8. Расчеты между Продавцом и Покупателем за Товар производятся способами, указанными на сайте Интернет-магазина в разделе  название раздела

`
            : `3.1. Тауардың әрбір позициясының бағасы интернет-дүкеннің веб-сайтында көрсетілген.
3.2. Сатушы Тауардың кез келген позициясының бағасын біржақты тәртіппен өзгертуге құқылы.
3.3. Тапсырыс берілген Тауардың бағасы өзгерген жағдайда Сатушы Сатып алушыны Тауар бағасының өзгергені туралы бірнеше күн ішінде хабардар етуге міндеттенеді.
3.4. Сатып алушы Тапсырыс бергеннен кейін Сатушы бағаны өзгерткен жағдайда Тауарды сатып алуға Тапсырысты растауға немесе жоюға құқылы.
3.5. Сатып алушы төлеген Тауардың бағасын Сатушының өзгертуіне жол берілмейді.
3.6. Сатушы Интернет-дүкеннің веб-сайтында Тауарды жеткізу құнын көрсетеді немесе Оператор тапсырыс берген кезде Сатып алушыға хабарлайды.
3.7. Сатып алушының Тауарды төлеу жөніндегі міндеттемелері Сатушы қаражатты алған сәттен бастап орындалды деп есептеледі.
3.8. Сатушы мен Сатып алушы арасындағы тауар бойынша есеп айырысу интернет-дүкеннің веб-сайтында бөлім тақырыбы бөлімінде көрсетілген әдістермен жүзеге асырылады.`
        }}
      </p>
    </div>
    <div class="payment-box">
      <h2 class="payment__h2 text-start">
        {{
          $cookie.get("lang") === "Ru"
            ? "4. ОФОРМЛЕНИЕ ЗАКАЗА"
            : "4. ТАПСЫРЫС БЕРУ"
        }}
      </h2>
      <p>
        {{
          $cookie.get("lang") === "Ru"
            ? `4.1. Заказ Товара осуществляется Покупателем через Оператора по телефону  +7 707 960 50 50 или через сервис сайта Интернет-магазина www.tahit.kz.  адрес раздела.
4.2. При регистрации на сайте Интернет-магазина Покупатель обязуется предоставить следующую регистрационную информацию:
4.2.1. фамилия, имя, отчество Покупателя или указанного им лица (получателя);
4.2.2.  адрес, по которому следует доставить Товар (если доставка до адреса Покупателя);
4.2.3. адрес электронной почты;
4.2.4. контактный телефон.
4.3. Наименование, количество, ассортимент, артикул, цена выбранного Покупателем Товара указываются в корзине Покупателя на сайте Интернет-магазина. 
4.4. Если Продавцу необходима дополнительная информация, он вправе запросить ее у Покупателя. В случае не предоставления необходимой информации Покупателем, Продавец не несет ответственности за выбранный Покупателем Товар.
4.5. При оформлении Заказа через Оператора (п. 4.1. настоящей Оферты) Покупатель обязуется предоставить информацию, указанную в п. 4.2. настоящей Оферты.
4.6. Принятие Покупателем условий настоящей Оферты осуществляется посредством внесения Покупателем соответствующих данных в регистрационную форму на сайте Интернет-магазина или при оформлении Заказа через Оператора. После оформления Заказа через Оператора данные о Покупателе регистрируются в базе данных Продавца. Утвердив Заказ выбранного Товара, Покупатель предоставляет Оператору необходимую информацию в соответствии с порядком, указанном в п. 4.2. настоящей Оферты.
4.7. Продавец не несет ответственности за содержание и достоверность информации, предоставленной Покупателем при оформлении Заказа.
4.8. Покупатель несет ответственность за достоверность предоставленной информации при оформлении Заказа.
4.9. Договор купли-продажи дистанционным способом между Продавцом и Покупателем считается заключенным с момента выдачи Продавцом Покупателю кассового или товарного чека либо иного документа, подтверждающего оплату Товара.

`
            : `4.1. Тауарға тапсырысты Сатып алушы Оператор арқылы +7 707 960 50 50 телефоны немесе www.tahit.kz интернет-дүкенінің веб-сайт қызметі арқылы жүзеге асырады. бөлімнің мекенжайы.
4.2. Интернет-дүкеннің веб-сайтында тіркелу кезінде Сатып алушы келесі тіркеу мәліметтерін беруге міндеттенеді:
4.2.1. Сатып алушының немесе ол (алушы) көрсеткен тұлғаның тегі, аты, әкесінің аты;
4.2.2. Тауар жеткізілуі тиіс мекенжай (егер жеткізу Сатып алушының мекенжайы бойынша болса);
4.2.3. Электрондық поштаның адресі;
4.2.4. Байланыс телефоны.
4.3. Сатып алушы таңдаған Тауардың атауы, саны, ассортименті, мақаласы, бағасы интернет-дүкеннің веб-сайтындағы Сатып алушының қоржынында көрсетілген.
4.4. Егер Сатушыға қосымша ақпарат қажет болса, ол оны Сатып алушыдан сұрауға құқылы. Сатып алушы қажетті ақпаратты бермесе, Сатушы Сатып алушы таңдаған Тауарлар үшін жауап бермейді.
4.5. Оператор арқылы Тапсырыс берген кезде (осы Офертаның 4.1. тармағы) Сатып алушы 4.2 тармағында көрсетілген ақпаратты беруге міндеттенеді. осы ұсыныс туралы.
4.6. Сатып алушының осы Ұсыныс шарттарын қабылдауы Интернет-дүкеннің веб-сайтындағы тіркеу формасына тиісті деректерді енгізу немесе Оператор арқылы Тапсырыс беру арқылы жүзеге асырылады. Оператор арқылы Тапсырыс бергеннен кейін Сатып алушының деректері Сатушы деректер базасында тіркеледі. Таңдалған Тауарға Тапсырысты бекіткеннен кейін Сатып алушы 4.2-тармақта көрсетілген тәртіпке сәйкес Операторға қажетті ақпаратты береді. осы ұсыныс туралы.
4.7. Сатушы Тапсырыс беру кезінде Сатып алушы ұсынған ақпараттың мазмұны мен дұрыстығына жауапты емес.
4.8. Сатып алушы Тапсырысты орналастыру кезінде берілген ақпараттың дұрыстығына жауап береді.
4.9. Сатушы мен Сатып алушы арасындағы қашықтан сатып алу-сату шарты Сатушы Сатып алушыға қолма-қол ақшаны немесе сату түбіртегін немесе Тауардың төленгенін растайтын өзге де құжатты берген кезден бастап жасалған болып есептеледі.`
        }}
      </p>
    </div>
    <div class="payment-box">
      <h2 class="payment__h2 text-start">
        {{
          $cookie.get("lang") === "Ru"
            ? "5.	ДОСТАВКА И ПЕРЕДАЧА ТОВАРА ПОКУПАТЕЛЮ"
            : "5. САТЫП АЛУШЫҒА ТАУАРДЫ ЖЕТКІЗУ ЖӘНЕ ӨТКІЗУ"
        }}
      </h2>
      <p>
        {{
          $cookie.get("lang") === "Ru"
            ? `5.1. Продавец оказывает Покупателю услуги по доставке Товара одним из способов указанных на сайте Интернет-магазина.
5.2. Если Договор купли-продажи товара дистанционным способом (далее – Договор) заключен с условием о доставке Товара Покупателю, Продавец обязан в установленный Договором срок доставить Товар в место, указанное Покупателем, а если место доставки Товара Покупателем не указано, то по месту его жительства или регистрации.
5.3. Место доставки Товара Покупатель указывает при оформлении Заказа на приобретение Товара.
5.4. Срок доставки Товара Покупателю состоит из срока обработки заказа и срока доставки.
5.5. Доставленный Товар передается Покупателю, а при отсутствии Покупателя - любому лицу, предъявившему квитанцию или иной документ, подтверждающий заключение Договора или оформление доставки Товара.
5.6. В момент передачи Товара в обязательном порядке в письменной форме Покупателю сообщаются сведения, предусмотренные в Приложении №  номер к Договору.
5.7. Информация о Товаре доводится до сведения Покупателя в технической документации, прилагаемой к Товару, на этикетках, путем нанесения маркировки или иным способом, принятым для отдельных видов товаров.
5.8. Сведения об обязательном подтверждении соответствия Товара представляются в порядке и способами, которые установлены законодательством Российской Федерации о техническом регулировании, и включают в себя сведения о номере документа, подтверждающего такое соответствие, о сроке его действия и об организации, его выдавшей.

`
            : `5.1. Сатушы Сатып алушыға интернет-дүкеннің веб-сайтында көрсетілген әдістердің бірімен Тауарды жеткізу қызметтерін ұсынады.
5.2. Егер Тауарды қашықтағы әдіспен сату шарты (бұдан әрі – Шарт) Тауарды Сатып алушыға жеткізу шартымен жасалса, Сатушы Тауарды Сатып алушы көрсеткен жерге жеткізуге міндетті. Шартта белгіленген мерзімде, ал егер Сатып алушының Тауарды жеткізу орны көрсетілмесе, оның тұрғылықты жері немесе тіркелген жері бойынша.
5.3. Сатып алушы Тауарды сатып алуға Тапсырыс беру кезінде Тауарды жеткізу орнын көрсетеді.
5.4. Тауарды Сатып алушыға жеткізу мерзімі тапсырысты өңдеу уақыты мен жеткізу уақытынан тұрады.
5.5. Жеткізілген Тауар Сатып алушыға, ал Сатып алушы болмаған жағдайда – Шарттың жасалғанын немесе Тауарды жеткізудің орындалғанын растайтын түбіртек немесе басқа құжатты ұсынған кез келген тұлғаға беріледі.
5.6. Тауарды беру кезінде Сатып алушыға Шартқа № № Қосымшада көзделген мәліметтер жазбаша түрде ұсынылады.
5.7. Тауар туралы ақпарат Сатып алушының назарына Тауарға қоса берілетін техникалық құжаттамада, заттаңбада, таңбалау арқылы немесе тауарлардың жекелеген түрлері үшін қабылданған басқа тәсілмен жеткізіледі.
5.8. Тауардың сәйкестiгiн мiндеттi растау туралы ақпарат Ресей Федерациясының техникалық реттеу туралы заңнамасында белгiленген тәртiппен және тәртiппен берiледi және мұндай сәйкестiктi растайтын құжаттың нөмiрi, оның қолданылу мерзiмi туралы ақпаратты қамтиды. және оны шығарған ұйым туралы.`
        }}
      </p>
    </div>
    <div class="payment-box">
      <h2 class="payment__h2 text-start">
        {{
          $cookie.get("lang") === "Ru"
            ? "6. РЕКВИЗИТЫ ПРОДАВЦА"
            : "6. САТУШЫ ТУРАЛЫ МӘЛІМЕТТЕР"
        }}
      </h2>
      <p>
        {{
          $cookie.get("lang") === "Ru"
            ? `Товарищество с 
ограниченной ответственностью

`
            : `-мен серіктестік
жауапкершілігі шектеулі`
        }}
      </p>
      <p>
        {{
          $cookie.get("lang") === "Ru"
            ? ` «Таит»
`
            : ` «Таит»`
        }}
      </p>
      <p>
        {{
          $cookie.get("lang") === "Ru"
            ? `г. Алматы, 
`
            : `Алматы,`
        }}
      </p>
      <p>
        {{
          $cookie.get("lang") === "Ru"
            ? `Улица Кунаева 43
`
            : `Қонаева көшесі 43`
        }}
      </p>
      <p>
        {{
          $cookie.get("lang") === "Ru"
            ? `БИН 200940031201
`
            : `БИН 200940031201`
        }}
      </p>
      <p>
        {{
          $cookie.get("lang") === "Ru"
            ? `Филиал АО «ForteBank» г. Алматы
`
            : `«ForteBank» АҚ филиалы, Алматы қ`
        }}
      </p>
      <p>
        {{
          $cookie.get("lang") === "Ru"
            ? `ИИК KZ7196502F0012660270
`
            : `ИИК KZ7196502F0012660270`
        }}
      </p>
      <p>
        {{
          $cookie.get("lang") === "Ru"
            ? `БИК IRTYKZKA
`
            : `БИК IRTYKZKA`
        }}
      </p>
      <p>
        {{
          $cookie.get("lang") === "Ru"
            ? `Внимательно ознакомьтесь с текстом публичной оферты, и если Вы не согласны с каким-либо пунктом оферты, Вы вправе отказаться от покупки Товаров, предоставляемых Продавцом, и не совершать действий, указанный в п. 2.1. настоящей Оферты.
`
            : `Ашық оферта мәтінін мұқият оқып шығыңыз, егер сіз офертаның қандай да бір тармағымен келіспесеңіз, Сатушы ұсынған Тауарды сатып алудан бас тартуға және 2.1 тармағында көрсетілген әрекеттерді жасамауға құқығыңыз бар. осы ұсыныс туралы.`
        }}
      </p>
      <p>
        {{
          $cookie.get("lang") === "Ru"
            ? `Согласен с договором 
`
            : `Мен келісім-шартпен келісемін`
        }}
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "AppOferta",
};
</script>

<style lang="scss" scoped>
.payment {
  padding-top: 30px;
  padding-bottom: 30px;

  &-box {
    padding: 50px 0;
    border-bottom: 1px solid #a5aab5;
  }
  &__h2 {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 130%;
    color: #1d393c;
    text-align: start;
  }
  &-flex {
    width: 90%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &-mail {
    color: #174baf;
  }
  &-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &-list {
    list-style-type: disc;
    text-align: left;
    font-family: "Lato Regular";
    font-style: normal;
    font-weight: 400;
    font-size: rem(18);
    line-height: 130%;
    color: #333333;
    margin: 20px 0;
    text-align: start;
    &__number {
      list-style-type: decimal;
      text-align: left;
      font-family: "Lato Regular";
      font-style: normal;
      font-weight: 400;
      font-size: rem(18);
      line-height: 130%;
      color: #333333;
      margin: 20px 0;
      text-align: start;
    }
  }
}

p {
  text-align: left;
  font-family: "Lato Regular";
  font-style: normal;
  font-weight: 400;
  font-size: rem(18);
  line-height: 130%;
  color: #333333;
  margin: 20px 0;
  text-align: start;
}

br {
  display: block;
  margin: 5px 0;
}

li {
  margin: 5px 0;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.mt-50 {
  margin-top: 50px;
}

.my-5 {
  margin: 5px 0;
}
</style>
